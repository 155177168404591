import React from "react"
import Layout from "components/layout"
import Seo from "components/seo"
import PageLead from "../../../components/pageLead";
import {Link} from "gatsby";

const PropertyWantedPage = () => {
    return (
        <Layout>
            <div className="property-wanted-page">
                <PageLead sub="ALLIANCE"
                          title="物件募集"
                />
                <section className="image-wrap">
                    <img src="https://stgokulabweb.blob.core.windows.net/images/alliance/property.jpg"
                         alt="Property Image" />
                </section>
                <section className="contents-wrap">
                    <div className="contents">
                        <h2 className="title">コインランドリー物件を<br className="sp"/>広く募集しています</h2>
                        <p className="description">以下3点にあてはまる物件がございましたらぜひご紹介ください。</p>
                        <ul>
                            <li>1階テナントであること</li>
                            <li>13坪以上の広さがあること</li>
                            <li>鉄筋コンクリート（RC造）、鉄骨（S造）または鉄筋鉄骨コンクリート（SRC造）</li>
                        </ul>
                        <p className="notification">※平屋の場合は木造でも可</p>
                        <div className="button-wrap">
                            <Link to="/contact" className="contact_btn">
                                <span className="pc">お問い合わせはこちら</span>
                                <span className="sp">お問い合わせ</span>
                                <span className="bracket" />
                            </Link>
                        </div>
                    </div>
                </section>

            </div>
        </Layout>
    )
}

export const Head = () => (
    <Seo
        title="物件募集"
        description="コインランドリー用物件を広く募集しています。1階の空きテナントなどの物件情報をお持ちの方、物件・土地の活用をご検討の方からのご連絡をお待ちしております。"
    />
)
export default PropertyWantedPage


